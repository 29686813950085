import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import { condition } from './condition.js'
import { auth } from './auth.js'

const store = createStore({
    modules: {
        auth,
        condition,
    },
    plugins: [
        createPersistedState({
            key: 'pim',
        })
    ]
})

export default store
