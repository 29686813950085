<template>
    <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark mb-3">
        <div class="container-fluid">
            <router-link class="navbar-brand" :to="{name: 'Home'}">PIM</router-link>
            <template v-if="$store.state.auth && $store.state.auth.is_signed_in">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="$store.state.auth.is_signed_in">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                製品管理
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="productDropdown">
                                <li>
                                    <router-link class="dropdown-item" :to="{name: 'ProductGroupList'}">製品一覧</router-link>
                                </li>
                                <li v-if="$store.getters['auth/canEditBasic']()">
                                    <router-link class="dropdown-item" :to="{name: 'ProductGroupAdd'}">製品新規登録</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" :to="{name: 'ExportJobList'}">エクスポート</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" :to="{name: 'ExportLayoutList'}">エクスポート定義</router-link>
                                </li>
                                <li v-if="$store.getters['auth/canEditBasic']()">
                                    <router-link class="dropdown-item" :to="{name: 'ImportJobList'}">インポート</router-link>
                                </li>
                                <li v-if="$store.getters['auth/canEditBasic']()">
                                    <router-link class="dropdown-item" :to="{name: 'ImportFiles'}">ファイル一括取込</router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="masterDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                マスタ管理
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="masterDropdown">
                                <template v-if="true">
                                    <li>
                                        <router-link class="dropdown-item" :to="{name: 'SpecLabel'}">規格項目マスタ</router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item" :to="{name: 'SpecUnit'}">規格単位マスタ</router-link>
                                    </li>
                                </template>
                                <template v-if="true">
                                    <li>
                                        <router-link class="dropdown-item" :to="{name: 'WholesaleRate'}">掛率マスタ</router-link>
                                    </li>
                                </template>
                                <template v-if="true">
                                    <li>
                                        <router-link class="dropdown-item" :to="{name: 'Retailer'}">商社マスタ</router-link>
                                    </li>
                                </template>
                                <template v-if="true">
                                    <li>
                                        <router-link class="dropdown-item" :to="{name: 'Catalog'}">カタログマスタ</router-link>
                                    </li>
                                </template>
                            </ul>
                        </li>

                        <template v-if="$store.getters['auth/canManageUser']()">
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="systemDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    システム管理
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="systemDropdown">
                                    <li>
                                        <router-link class="dropdown-item" to="/user">ユーザ管理</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-person-circle"></i> {{ user_name }} さん
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="productDropdown">
                                <li>
                                    <router-link class="dropdown-item" :to="{name: 'Account'}">アカウント設定</router-link>
                                </li>
                                <li>
                                    <router-link class="dropdown-item" :to="{name: 'Preference'}">製品表示項目設定</router-link>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#" @click="signOut()">ログアウト</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- 右サイド -->
                    <form @submit.prevent="search()" class="d-flex">
                        <form-input
                            v-model="condition.keyword"
                            optional_class="form-control me-2"
                            type="search"
                            placeholder="キーワード"
                        ></form-input>
                        <button-search
                            type="submit"
                            text=""
                            optional_class="bg-white text-dark btn-outline-light"
                        ></button-search>
                        <!-- aiqのチャットボットサイト（社内向け）リンク -->
                        <a href="https://agi.ai-q.biz/9SrqRGKa3Stgyg/chat/top" target="_blank" class="ms-2 btn btn-light"><i class="bi bi-robot"></i></a>
                    </form>
                </div>
            </template>
        </div>
    </nav>

    <div class="container-fluid container-main">
        <router-view/>
    </div>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/components/tools/ScreenLoader.vue'
import MessageDialog from '@/components/tools/MessageDialog.vue'
import FormInput from '@/components/forms/FormInput';
import ButtonSearch from '@/components/buttons/ButtonSearch';

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog,
        FormInput,
        ButtonSearch,
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
        }
    },
    data() {
        return {
            loading: 0,

            dialog: {
                title: null,
                message: null,
                icon: null
            },

            //検索オプション
            condition: {
                keyword: null,
                page: 1,
            },
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });
    },
    methods: {
        signOut() {
            this.$store.commit('auth/signOut') //ログイン情報削除
            this.$store.commit('condition/signOut') //検索条件削除
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        },
        //検索
        search(page = 1) {
            this.condition.page = page;
            
            //検索条件を保存
            this.$store.commit('condition/setSearchCondition', {
                page: 'ProductGroupList',
                condition: this.condition
            });

            //製品一覧ページ内でも機能させるため（同一ページ遷移）queryを付ける
            let today = new Date();
            this.$router.push({name: 'ProductGroupList', query: {date: today.getTime()}});
        },
    },
    computed: {
        user_name() {
            return this.$store.state.auth.user_name;
        }
    },
}
</script>

<style>
.container {
    background: #FFF;
    padding-top: 1em;
    padding-bottom: 1em;
}
.modal-dialog {margin: 10% auto !important;}
</style>
