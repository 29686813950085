import Enum from './enum'

/**
 * 仮パスワードフラグ
 */
class IsProvisionalPassword extends Enum {
    static YES = 1; // YES
    static NO = 0; // NO

    static values() {
        return {
            [this.YES]: 'YES',
            [this.NO]: 'NO',
        }
    }
}

export default IsProvisionalPassword;
