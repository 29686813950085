import { createRouter, createWebHistory } from 'vue-router'
import PageSignin from '../pages/PageSignin.vue'
import store from '../store/index.js'
import IsProvisionalPassword from '@/models/enums/is-provisional-password'
import AccountType from '@/models/enums/account-type'

const routes = [
    {
        //ログイン
        path: '/signin',
        name: 'Signin',
        meta: { for_guest: true },
        component: PageSignin
    },
    {
        //初期パスワード変更
        path: '/password',
        name: 'Password',
        component: () => import('../pages/PagePassword.vue')
    },

    /**
     * Dashboard
     */
    {
        path: '/',
        name: 'Home',
        component: () => import('../pages/PageHome.vue')
    },

    /**
     * 1.製品管理
     */
    {
        // A11 製品一覧
        path: '/products',
        name: 'ProductGroupList',
        component: () => import('../pages/product/PageProductGroupList.vue')
    },
    {
        // A12 製品閲覧（マスタ）
        path: '/products/:id',
        name: 'ProductGroupView',
        component: () => import('../pages/product/PageProductGroupView.vue')
    },
    {
        // A12 製品閲覧（リビジョン）
        path: '/products/:id/revision/:revision',
        name: 'ProductGroupRevisionView',
        component: () => import('../pages/product/PageProductGroupView.vue')
    },
    {
        // A13 製品新規登録
        path: '/products/add',
        name: 'ProductGroupAdd',
        component: () => import('../pages/product/PageProductGroupAdd.vue')
    },
    {
        // A14 製品編集
        path: '/products/:id/edit',
        name: 'ProductGroupEdit',
        component: () => import('../pages/product/PageProductGroupEdit.vue')
    },
    {
        // A15 製品編集履歴
        path: '/products/:id/history',
        name: 'ProductGroupHistory',
        component: () => import('../pages/product/PageProductGroupHistory.vue')
    },
    {
        // A16 エクスポート履歴
        path: '/products/export',
        name: 'ExportJobList',
        component: () => import('../pages/product/PageExportJobList.vue')
    },
    {
        // A17 インポート履歴
        path: '/products/import',
        name: 'ImportJobList',
        meta: { gate: 'canEditBasic' },
        component: () => import('../pages/product/PageImportJobList.vue')
    },
    {
        // A17 インポート予約
        path: '/products/import/add',
        name: 'ImportJobAdd',
        meta: { gate: 'canEditBasic' },
        component: () => import('../pages/product/PageImportJobAdd.vue')
    },
    {
        // A18 ファイル一括取込
        path: '/products/import/files',
        name: 'ImportFiles',
        meta: { gate: 'canEditBasic' },
        component: () => import('../pages/product/PageImportFiles.vue')
    },

    /**
     * 2.マスタ管理
     */
    {
        // A21 規格項目マスタ
        path: '/master/spec-label',
        name: 'SpecLabel',
        component: () => import('../pages/master/PageStandardSpecLabel.vue')
    },
    {
        // A22 規格単位マスタ
        path: '/master/spec-unit',
        name: 'SpecUnit',
        component: () => import('../pages/master/PageSpecUnit.vue')
    },
    {
        // A23 掛率マスタ
        path: '/master/wholesale-rate',
        name: 'WholesaleRate',
        component: () => import('../pages/master/PageWholesaleRate.vue')
    },
    {
        // A24 商社マスタ
        path: '/master/retailer',
        name: 'Retailer',
        component: () => import('../pages/master/PageRetailer.vue')
    },
    {
        // A25 カタログマスタ
        path: '/master/catalog',
        name: 'Catalog',
        component: () => import('../pages/master/PageCatalog.vue')
    },

    /**
     * 3.システム管理
     */
    {
        // A31 ユーザ一覧
        path: '/user',
        name: 'UserList',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/system/PageUserList.vue')
    },
    {
        // A32 ユーザ登録
        path: '/user/add',
        name: 'UserAdd',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/system/PageUserAdd.vue')
    },
    {
        // A33 ユーザ編集
        path: '/user/:id/edit',
        name: 'UserEdit',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/system/PageUserEdit.vue')
    },

    /**
     * 4.個人設定
     */
    {
        // A41 アカウント設定
        path: '/account',
        name: 'Account',
        component: () => import('../pages/preference/PageAccount.vue')
    },
    {
        // A42 個人設定
        path: '/preference',
        name: 'Preference',
        component: () => import('../pages/preference/PagePreference.vue')
    },

    /**
     * 5.エクスポート定義
     */
    {
        // A51 エクスポート定義一覧
        path: '/export-layout',
        name: 'ExportLayoutList',
        component: () => import('../pages/export-layout/PageExportLayoutList.vue')
    },
    {
        // A52 エクスポート定義登録
        path: '/export-layout/add',
        name: 'ExportLayoutAdd',
        component: () => import('../pages/export-layout/PageExportLayoutAdd.vue')
    },
    {
        // A53 エクスポート定義編集
        path: '/export-layout/:id/edit',
        name: 'ExportLayoutEdit',
        component: () => import('../pages/export-layout/PageExportLayoutEdit.vue')
    },

    /**
     * エラー系
     */
    {
        //403ページ
        path: '/error/403',
        name: 'Error403',
        component: () => import('../pages/error/PageError403.vue')
    },
    {
        //404ページ ＝ 上記のいずれにもマッチしないもの
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: () => import('../pages/error/PageError404.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.every(record => record.meta.for_guest)) {
        //ログインの不要な画面
        next();
    } else {
        //ログインの必要な画面
        if (to.matched.some((record) => !record.meta.for_guest) && !store.state.auth.access_token) {
            //ログイン切れ
            next({ path: '/signin', query: { redirect: to.fullPath } });
        } else {
            if (store.state.auth.account_type === AccountType.GOOGLE_ACCOUNT) {
                //Googleログインの場合
                next();
            }

            if (
                store.state.auth.account_type === AccountType.ID_PASSWORD
                && store.state.auth.is_provisional_password === IsProvisionalPassword.YES
            ) {
                //ID・パスワードログイン かつ 仮パスワードである
                if (to.matched.some(record => record.name === 'Password')) {
                    next();
                } else {
                    next({ path: '/password' });
                }

            } else {
                //権限なし
                for (let record of to.matched) {
                    if (record.meta && record.meta.gate) {
                        if (false === store.getters['auth/' + record.meta.gate]()) {
                            next({ path: '/error/403' });
                        }
                    }
                }
                next();
            }
        }
    }
});

export default router
