import Enum from './enum'

class Role extends Enum {
    static VIEW_PRODUCTION = 10; // 製作情報閲覧

    static EDIT_BASIC = 20; // 製品基本情報編集
    static EDIT_CATALOG = 21; // カタログ用情報編集
    static EDIT_PRICE = 22; // 価格情報編集
    static EDIT_STANDARD = 23; // 規格図情報編集
    static EDIT_PRODUCTION = 24; // 製作図情報編集

    static MANAGE_STANDARD_SPEC_LABEL = 30; // 規格マスタ管理
    static MANAGE_WHOLESALE_RATE = 31; // 掛率マスタ管理
    static MANAGE_RETAILER = 32; // 商社マスタ管理
    static MANAGE_CATALOG = 33; // カタログマスタ管理

    static MANAGE_USER = 90; // ユーザ管理

    static values() {
        return {
            [this.VIEW_PRODUCTION]: '製作情報閲覧',

            [this.EDIT_BASIC]: '製品基本情報編集',
            [this.EDIT_CATALOG]: 'カタログ用情報編集',
            [this.EDIT_PRICE]: '価格情報編集',
            [this.EDIT_STANDARD]: '規格図情報編集',
            [this.EDIT_PRODUCTION]: '製作図情報編集',

            [this.MANAGE_STANDARD_SPEC_LABEL]: '規格マスタ管理',
            [this.MANAGE_WHOLESALE_RATE]: '掛率マスタ管理',
            [this.MANAGE_RETAILER]: '商社マスタ管理',
            [this.MANAGE_CATALOG]: 'カタログマスタ管理',

            [this.MANAGE_USER]: 'ユーザ管理',
        }
    }
}

export default Role;
