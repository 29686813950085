import Enum from './enum'

/**
 * ユーザ アカウントタイプ
 */
class AccountType extends Enum {
    static GOOGLE_ACCOUNT = 1; // Googleアカウント
    static ID_PASSWORD = 2; // ログインID・パスワード

    static values() {
        return {
            [this.GOOGLE_ACCOUNT]: 'Googleアカウント',
            [this.ID_PASSWORD]: 'ログインID・パスワード',
        }
    }
}

export default AccountType;
