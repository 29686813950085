<template>
    <header class="page-title">
        <h1><i class="bi bi-key"></i> ログイン</h1>
    </header>

    <section class="section">

        <h2 class="section-title">Googleアカウントでログイン</h2>

        <a :href="google_auth_url" class="btn btn-primary btn-lg"><i class="bi bi-google"></i> ログイン</a>

        <div class="mt-3">@agi.co.jp の Google アカウントでのみログインできます。<br>
        他の Google アカウントでログインしている場合、エラーとなりますので、 <a href="https://myaccount.google.com/?hl=ja" target="_blank">Google アカウント</a> からログアウトしてください。</div>

    </section>

    <section class="section">

        <h2 class="section-title">ログインID・パスワードでログイン</h2>

        <form @submit.prevent="signin" class="col-md-8">
            <div class="mb-3">
                <label>ログインID</label>
                <input type="text" v-model="login_key" class="form-control" required="required">
            </div>
            <div class="mb-3">
                <label>パスワード</label>
                <input type="password" v-model="password" class="form-control" required="required" autocomplete="off">
                <p class="text-secondary">＊大文字を含む英数字8文字以上</p>
            </div>
            <div class="mb-3">
                <button class="btn btn-primary btn-lg"><i class="bi bi-key"></i> ログイン</button>
            </div>
        </form>

        <p>パスワードを忘れた場合は、経営企画推進室までご連絡ください。</p>

    </section>

</template>

<script>
import axios from 'axios';

export default {
    components: {},
    name: 'Signin',
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
    ],
    data() {
        return {
            login_key: null,
            password: null,
            google_auth_url: process.env.VUE_APP_API_URI + '/redirect/google-auth',
        }
    },
    mounted() {
        let code = this.$route.query.code;
        if (code) {
            this.signinGoogle(code);
        }
    },
    methods: {
        signin() {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_URI + '/signin', {
                login_key: this.login_key,
                password: this.password
            })
            .then((response) => {
                this.$store.commit('auth/setAccessToken', response.data);
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    if (this.$store.state.auth.is_provisional_password) {
                        this.$router.push({name: 'Password'});

                    } else {
                        this.$router.push('/');
                    }
                }
            })
            .catch((error) => {
                //※ここは $http 使っていないので、自力でメッセージ表示が必要
                if (error.response && error.response.status === 422) {
                    this.showErrorMessage('メールアドレスかパスワードが違います');

                } else {
                    this.showErrorMessage(error.response.data.message);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        signinGoogle(code) {
            this.startScreenLoading();
            axios.post(process.env.VUE_APP_API_URI + '/signin/google', {
                code,
            })
            .then((response) => {
                this.$store.commit('auth/setAccessToken', response.data);
                //リダイレクト指定があれば
                if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                //※ここは $http 使っていないので、自力でメッセージ表示が必要
                if (error.response && error.response.status === 422) {
                    this.showErrorMessage('Googleログインに失敗しました');

                } else {
                    this.showErrorMessage(error.response.data.message);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
