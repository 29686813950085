import Role from "@/models/enums/role";
import IsProvisionalPassword from "@/models/enums/role";

export const auth = {
    namespaced: true,
    state: {
        user_name: null,
        account_type: null,
        roles: [],
        access_token: null,
        access_token_expired: null,
        access_token_issued: null,
        refresh_token: null,
        refresh_token_expired: null,
        refresh_token_issued: null,
        is_provisional_password: null,
        is_signed_in: false,

    },
    mutations: {
        //APIのコールバックでよぶには actions が必要なはずだが
        //普通に呼べている件
        setAccessToken (state, param) {
            state.user_name = param.user_name;
            state.account_type = param.account_type;
            state.roles = param.roles ? param.roles.map(val => parseInt(val, 10)) : [];
            state.access_token = param.access_token;
            state.access_token_expired = param.access_token_expired;
            state.access_token_issued = param.access_token_issued;
            state.refresh_token = param.refresh_token;
            state.refresh_token_expired = param.refresh_token_expired;
            state.refresh_token_issued = param.refresh_token_issued;
            state.is_provisional_password = param.is_provisional_password;
            state.is_signed_in = true;
        },
        setIsProvisionalPassword (state) {
            state.is_provisional_password = IsProvisionalPassword.NO;
        },
        //ログアウト時処理
        signOut (state) {
            state.user_name = null;
            state.account_type = null;
            state.roles = [];
            state.access_token = null;
            state.access_token_expired = null;
            state.access_token_issued = null;
            state.refresh_token = null;
            state.refresh_token_expired = null;
            state.refresh_token_issued = null;
            state.is_provisional_password = null;
            state.is_signed_in = false;
// alert(JSON.stringify(state, null, 2));
        },
    },
    getters: {
        //ユーザ管理権限
        canManageUser: (state) => () => {
            return (state.roles.includes(Role.MANAGE_USER));
        },

        //製品基本情報編集
        canEditBasic: (state) => () => {
            return (state.roles.includes(Role.EDIT_BASIC));
        },
        //カタログ用情報編集
        canEditCatalog: (state) => () => {
            return (state.roles.includes(Role.EDIT_CATALOG));
        },
        //価格情報編集
        canEditPrice: (state) => () => {
            return (state.roles.includes(Role.EDIT_PRICE));
        },
        //規格図情報編集
        canEditStandard: (state) => () => {
            return (state.roles.includes(Role.EDIT_STANDARD));
        },
        //製作図情報編集
        canEditProduction: (state) => () => {
            return (state.roles.includes(Role.EDIT_PRODUCTION));
        },

        //いずれかの編集権限を持つ
        canEditAny: (state) => () => {
            if (
                state.roles.includes(Role.EDIT_BASIC) ||
                state.roles.includes(Role.EDIT_CATALOG) ||
                state.roles.includes(Role.EDIT_PRICE) ||
                state.roles.includes(Role.EDIT_STANDARD) ||
                state.roles.includes(Role.EDIT_PRODUCTION)
            ) {
                return true;
            }

            return false;
        },

        //カタログマスタ管理
        canManageCatalog: (state) => () => {
            return (state.roles.includes(Role.MANAGE_CATALOG));
        },
        //規格マスタ管理
        canManageStandardSpecLabel: (state) => () => {
            return (state.roles.includes(Role.MANAGE_STANDARD_SPEC_LABEL));
        },
        //掛率マスタ管理
        canManageWholesaleRate: (state) => () => {
            return (state.roles.includes(Role.MANAGE_WHOLESALE_RATE));
        },
        //商社マスタ管理
        canManageRetailer: (state) => () => {
            return (state.roles.includes(Role.MANAGE_RETAILER));
        },

        //製品情報閲覧
        canViewProduction: (state) => () => {
            return (state.roles.includes(Role.VIEW_PRODUCTION));
        },
    },
}
