<template>
    <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        type="text"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        class="form-control"
        :class="{[optional_class]: true}"
    >
</template>

<script>
export default {
    name: 'FormInput',
    props: {
        modelValue: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        optional_class: {
            type: String,
            default: ''
        },
    },
    emits: [
        'update:modelValue'
    ],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
